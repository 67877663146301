import consumer from "./consumer"

$(function () {
  if ($("#tenant-admin").data('tenant-id') ) {
    // console.log("Auction Admin Channel " + $("#tenant-admin").data('tenant-id'));
    consumer.subscriptions.create({
          channel: "AdminChannel",
          tenant_id: $("#tenant-admin").data('tenant-id')
        }, {
        connected() {
          // Called when the subscription is ready for use on the server
          // console.log('Admin Connected');
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          if (data['action'] == "bid") {
            $.ajax({
              url: data['params']['url'],
              data: {
                bid_id: data['params']['bid_id'],
                item_id: data['item_id'],
                event: data['event']
              },
              success: function (result) {
              }
            });
          }

          if (data['action'] == "item_close") {
            $.ajax({
              url: data['params']['url'],
              data: {
                item_id: data['params']['item_id'],
                auction_id: data['params']['auction_id'],
                event: data['params']['event']
              },
              success: function (result) {
              }
            });
          }

          if (data['action'] == "auction_item_upload") {
            $.ajax({
              url: data['params']['url'],
              data: {
                auction_item_upload_id: data['params']['auction_item_upload_id']
              },
              success: function (result) {
              }
            });
          }

          if (data['action'] == "auction_item_image_upload") {
            $.ajax({
              url: data['params']['url'],
              data: {
                auction_item_image_upload_id: data['params']['auction_item_image_upload_id']
              },
              success: function (result) {
              }
            });
          }

        }
      }
    );
  }
});
