function scrollHeader() {
  // if the scroll distance is greater than 100px
  if ($(window).scrollTop() > 42) {
    // do something
    $('.site-header').addClass('scrolled-header');
  } else {
    $('.site-header').removeClass('scrolled-header');
  }
};

$(document).on('scroll', function () {
  scrollHeader();
});