document.addEventListener('DOMContentLoaded', function () {
  testimonialSetup();
})

function testimonialSetup() {
  var owl = $("#owl-testimonials");

  owl.owlCarousel({
      navigation : false,
    navigationText : ["prev","next"],
      pagination : false,
    paginationNumbers: false,
      autoPlay: 5000, //Set AutoPlay to 3 seconds
    items : 1, //10 items above 1000px browser width
    itemsDesktop : [1000,1], //5 items between 1000px and 901px
    itemsDesktopSmall : [900,1], // betweem 900px and 601px
    itemsTablet: [600,1], //2 items between 600 and 0
    itemsMobile : false // itemsMobile disabled - inherit from itemsTablet option
  });

  // Custom Navigation Events
  $(".next").on('click' ,function(){
    owl.trigger('owl.next');
  })
  $(".prev").on('click' ,function(){
    owl.trigger('owl.prev');
  })
  $(".play").on('click', function(){
    owl.trigger('owl.play',1000); //owl.play event accept autoPlay speed as second parameter
  })
  $(".stop").on('click', function(){
    owl.trigger('owl.stop');
  })

}
