document.addEventListener('DOMContentLoaded', function () {
  clientSetup();
})

function clientSetup() {
  var owl = $("#owl-clients");

  owl.owlCarousel({

    pagination : false,
    paginationNumbers: false,
    autoPlay: 3000, //Set AutoPlay to 3 seconds
    items : 6, //10 items above 1000px browser width
    itemsDesktop : [1000,5], //5 items between 1000px and 901px
    itemsDesktopSmall : [900,3], // betweem 900px and 601px
    itemsTablet: [600,1], //2 items between 600 and 0
    itemsMobile : false // itemsMobile disabled - inherit from itemsTablet option
  });
}