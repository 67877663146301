document.addEventListener('DOMContentLoaded', function () {

    'use-strict';

		function showHide(shID) {
			if (document.getElementById(shID)) {
				if (document.getElementById(shID + '-show').style.display != 'none') {
					document.getElementById(shID + '-show').style.display = 'none';
					document.getElementById(shID).style.display = 'block';
				} else {
					document.getElementById(shID + '-show').style.display = 'inline';
					document.getElementById(shID).style.display = 'none';
				}
			}
		}
		window.showHide = showHide;

		// Off Canvas Navigation
		var offcanvas_open = false;
		var offcanvas_from_left = false;

		function offcanvas_right() {

			$(".sidebar-menu-container").addClass("slide-from-left");
			$(".sidebar-menu-container").addClass("sidebar-menu-open");

			offcanvas_open = true;
			offcanvas_from_left = true;

			$(".sidebar-menu").addClass("open");
			$("body").addClass("offcanvas_open offcanvas_from_left");

			// $(".nano").nanoScroller();

		}

		function offcanvas_close() {
			if (offcanvas_open === true) {

				$(".sidebar-menu-container").removeClass("slide-from-left");
				$(".sidebar-menu-container").removeClass("sidebar-menu-open");

				offcanvas_open = false;
				offcanvas_from_left = false;

				//$('#sidebar-menu-container').css('max-height', 'inherit');
				$(".sidebar-menu").removeClass("open");
				$("body").removeClass("offcanvas_open offcanvas_from_left");

			}
		}

		$(".side-menu-button").on('click', function() {
			offcanvas_right();
		});

		$("#sidebar-menu-container").on("click", ".sidebar-menu-overlay", function(e) {
			offcanvas_close();
		});

		$(".sidebar-menu-overlay").swipe({
			swipeLeft:function(event, direction, distance, duration, fingerCount) {
				offcanvas_close();
			},
			swipeRight:function(event, direction, distance, duration, fingerCount) {
				offcanvas_close();
			},
			tap:function(event, direction, distance, duration, fingerCount) {
				offcanvas_close();
			},
			threshold:0
		});


		// Mobile navigation
		$(".responsive-menu .menu-item-has-children").append('<div class="show-submenu"><i class="far fa-chevron-circle-down"></i></div>');

	    $(".responsive-menu").on("click", ".show-submenu", function(e) {
			e.stopPropagation();

			$(this).parent().toggleClass("current")
							.children(".sub-menu").toggleClass("open");

			$(this).html($(this).html() == '<i class="far fa-chevron-circle-down"></i>' ? '<i class="far fa-chevron-circle-up"></i>' : '<i class="far fa-chevron-circle-down"></i>');
			// $(".nano").nanoScroller();
		});

		$(".responsive-menu").on("click", "a", function(e) {
			if( ($(this).attr('href') === "#") || ($(this).attr('href') === "") ) {
				$(this).parent().children(".show-submenu").trigger("click");
				return false;
			} else {
				offcanvas_close();
			}
		});
	});
