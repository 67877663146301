document.addEventListener('DOMContentLoaded', function () {
  flatpickrSetup();

})

function flatpickrSetup() {
  flatpickr('.flatpickr', {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    minuteIncrement: 30
  });
}
window.flatpickrSetup = flatpickrSetup