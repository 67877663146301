// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
require("trix")
require("@rails/actiontext")
require("channels")

// This seems to be important since we can't access $ in the remote page responses.
global.$ = jQuery;

// Node Imports
import "bootstrap-sass"

// Application Imports
import "../stylesheets/marketing"

// Node Module Includes
require("jquery-ui")
require("flatpickr")

// Local Module Includes
require("../custom/nav")
require("../custom/site_header")
require("../custom/jquery_waypoints")
require("../custom/owl_carousel")
require("../custom/image_pack")

// Initializations
require("../custom/partials/action_text")
require("../custom/partials/feedback_form")
require("../custom/partials/flatpickr")
require("../custom/partials/owl_clients")
require("../custom/partials/owl_testimonials")
require("../custom/partials/scroll_header")
require("../custom/partials/tabs")
require("../custom/partials/tooltips")

Rails.start()
// Turbolinks.start()
ActiveStorage.start()