document.addEventListener('DOMContentLoaded', function () {

  // clicking on the tabs in the page once it is loaded
  $('.tabs .tab-links a').on('click', function (e) {
    var currentAttrValue = $(this).attr('href');

    // Show/Hide Tabs
    $('.tabs ' + currentAttrValue).fadeIn(500).siblings().hide();;

    // Change/remove current tab to active
    $(this).parent('li').addClass('active').siblings().removeClass('active');

    e.preventDefault();
  });



  // Redirect to the right tab when we first click into the profile page
  var hash = document.location.hash;
  var prefix = "tab_";
  if (hash) {
    $('.tab-links a[href="' + hash.replace(prefix, "") + '"]').tab('show');
    window.scrollTo(0, 0);
  };

  // This covers the clicks from the top nav if we are already on the page
  $('.sub-menu li a').on('click', function (e) {
    var currentAttrValue = $(this).attr('href');

    // Show/Hide Tabs
    $('.tabs #' + currentAttrValue.split("#")[1]).fadeIn(500).siblings().hide();;

    // Change/remove current tab to active
    $('.tab-links li a[href="#' + currentAttrValue.split("#")[1] + '"]').parent().addClass('active').siblings().removeClass('active')
  });

});
