import consumer from "./consumer"

$(function () {

  // Since we want to listen on both the auction pages and the watch list
  // We have to make some fuckery to make that work

  if( $("#auction").data('auction-id') || $(".watch_list").data('auction-id') ) {
    var auction_ids = [];

    // Collect the Watch List Ids
    $(".watch_list").each(function() {
      auction_ids.push($(this).data('auction-id'));
    });

    // Add main listener
    if($("#auction").data('auction-id')) {
      auction_ids.push($("#auction").data('auction-id'));
    };


    auction_ids.forEach(function(auction_id) {
      // console.log("Auction Channel " + auction_id);
      consumer.subscriptions.create({
            channel: "AuctionChannel",
            auction_id: auction_id
          }, {
          connected() {
            // Called when the subscription is ready for use on the server
            // console.log('Auction Connected');
          },

          disconnected() {
            // Called when the subscription has been terminated by the server
          },

          received(data) {
            $.ajax({
              url: data['item_url'],
              success: function (result) {
                // $("#div1").html(result);
              }
            });
          }
        }
      );
    }); // Close Loop
  }
});